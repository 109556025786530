import firebase from 'firebase';
import 'firebase/auth';
const firebaseConfig = {
	apiKey: 'AIzaSyDsd1LSI6OKfRodu2Vy8TAAlK9XJA8HDZE',
	authDomain: 'agile-grade.firebaseapp.com',
	projectId: 'agile-grade',
	storageBucket: 'agile-grade.appspot.com',
	messagingSenderId: '848234194906',
	appId: '1:848234194906:web:295a31f226d485d5d242e3',
	measurementId: 'G-CCZRMSXF1E',
};
// const firebaseConfig = {
// 	apiKey: "AIzaSyBJfKUmF3VFRh6dDdDGxmZUWOhLNh8TJXQ",
// 	authDomain: "agile-grade-nextpak.firebaseapp.com",
// 	projectId: "agile-grade-nextpak",
// 	storageBucket: "agile-grade-nextpak.appspot.com",
// 	messagingSenderId: "590503812589",
// 	appId: "1:590503812589:web:a548a60abc03101e9f1be4",
// 	measurementId: "G-7VM2YEL04W"
//   };
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;
