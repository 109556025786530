import React from 'react';
import {
	Card,
	CardHeader,
	Table,
	Container,
	Row,
	Nav,
	NavItem,
	NavLink,
	Spinner,
	Badge,
} from 'reactstrap';
import OnlyHeader from 'components/Headers/OnlyHeader.js';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'components/Headers/Header';

const Dashboard = () => {
	return (
		<>
			<Header />
		</>
	);
};

export default Dashboard;
