const initialState = {
	loading: false,
	user: null,
};
const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SIGNUP_SUCCESS':
			return {
				...state,
				user: action.payload,
			};
		case 'SIGN_IN':
			return {
				...state,
				user: action.payload,
			};
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				user: null,
			};
		default:
			return state;
	}
};
export default authReducer;
