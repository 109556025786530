import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	useLocation,
} from 'react-router-dom';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import './assets/css/index.css';
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { ToastContainer } from 'react-toastify';
import { Button, Modal, ModalBody } from 'reactstrap';
import { changeWelcomeStatus } from 'store/action/authActions';
import { getMyScore } from 'store/action/submitQuestionsAction';
import { setUserGrade } from 'store/action/submitQuestionsAction';

import BadgeVerification from 'views/BadgeVerification';
import routes from 'routes';

function App() {
	let { user } = useSelector(state => state.auth);
	let { myScore, submissions, questions } = useSelector(
		state => state.questions
	);
	let dispatch = useDispatch();
	let [welcomeModal, setWelcomeModal] = useState(false);
	const calculateUserGrade = (score, totalSubmittedQuestions) => {
		let percent = (score * 100) / totalSubmittedQuestions;
		switch (true) {
			case percent == 100:
				return 0;
			case percent >= 90:
				return 1;
			case percent >= 80:
				return 2;
			case percent >= 70:
				return 3;
			default:
				return 4;
		}
	};
	useEffect(async () => {
		if (user) {
			if (myScore != null && Object.keys(myScore).length == 0) {
				await dispatch(getMyScore(user.id));
			} else {
				if (myScore == null) {
					dispatch(setUserGrade(5));
				}
				// else {
				// 	let newGrade = calculateUserGrade(
				// 		myScore.score,
				// 		questions.length
				// 	);

				// 	dispatch(setUserGrade(newGrade));
				// }
			}
			if (!user.isWelcomeShown) {
				setWelcomeModal(true);
			}
		}
	}, [user, myScore]);

	return (
		<>
			<BrowserRouter>
				<Switch>
					{routes.map(({ layout, path, component: Children }) => {
						switch (layout) {
							case '/admin':
                console.log(layout + path)
								return (
									<Route exact path={layout + path}>
										<AdminLayout>
											<Children />
										</AdminLayout>
									</Route>
								);
							case '/auth':
								return (
									<Route exact path={layout + path}>
										<AuthLayout>
											<Children />
										</AuthLayout>
									</Route>
								);
							default:
								return (
									<Route exact path={path}>
										<Children />
									</Route>
								);
						}
					})}
					<Redirect from='*' to='/' />
				</Switch>
				{/* {user ? (
					<Switch>
						<Route
							path='/admin'
							render={props => <AdminLayout {...props} />}
						/>
						<Route
							path='/verify/badge/:id'
							component={BadgeVerification}
							exact
						/>
						<Redirect from='/' to='/admin/buy-cert' />{' '}
					</Switch>
				) : (
					<Switch>
						<Route
							path='/auth'
							render={props => <AuthLayout {...props} />}
						/>
						<Route
							path='/verify/badge/:id'
							component={BadgeVerification}
							exact
						/>

						<Redirect from='/' to='/auth/login' />
					</Switch>

					// <Switch>
					// 	<Route
					// 		path='/public'
					// 		render={props => <PublicLayout {...props} />}
					// 	/>
					// </Switch>
				)} */}
			</BrowserRouter>
			{/* welcome model */}
			<Modal
				className=''
				isOpen={welcomeModal}
				toggle={() => {
					dispatch(changeWelcomeStatus(user));
					// resetTimer();
					setWelcomeModal(false);
				}}
				size='md'
				centered
			>
				<ModalBody>
					<div className='text-center py-3'>
						<h2>Welcome to AgileGrade!</h2>
						<p className='mb-1'>
							Start answering questions and get Certified
							according to your Agile Knowledge
						</p>
					</div>

					<Button
						className='w-100'
						color='success'
						onClick={() => {
							dispatch(changeWelcomeStatus(user));
							// resetTimer();
							setWelcomeModal(false);
						}}
					>
						Proceed
					</Button>
				</ModalBody>
			</Modal>

			<ToastContainer position='top-right' hideProgressBar={false} />
			{/* Same as */}
		</>
	);
}

export default App;
