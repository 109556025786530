import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import OnlyHeader from 'components/Headers/OnlyHeader.js';
import CourseCard from 'components/CourseCard';
const Courses = () => {
	let books = [
		{
			title: 'The Scrum Guide 2020',
			name: 'Scrum Guide',
			link: 'https://scrumguides.org/',
		},
		{
			title: 'Manifesto for Agile Software Development',
			name: 'Agile Manifesto',
			link: 'https://agilemanifesto.org/',
		},
		{
			title: 'Scrum: The Art of Doing Twice the Work in Half the Time',
			name: 'BUY on AMAZON',
			link: 'https://www.amazon.com/-/es/dp/B00NHZ6PPE/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3BQ9EHVVGRQLD&keywords=scrum+the+art+of+doing+twice+the+work+in+half+the+time&qid=1675873961&sprefix=scrum+the+art+of+doing+twice+the+work+in+half+the+time%2Caps%2C121&sr=8-1',
		},
		{
			title: 'Coaching Agile Teams',
			name: 'BUY on AMAZON',
			link: 'https://www.amazon.com/-/es/dp/B08QTX31HV/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3E35CWS3BLG49&keywords=coaching+agile+teams&qid=1675873988&sprefix=coaching+agile+teams%2Caps%2C116&sr=8-1',
		},
		{
			title: 'Succeeding with Agile',
			name: 'BUY on AMAZON',
			link: ' https://www.amazon.com/-/es/Mike-Cohn/dp/0321579364/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2GV7W604X86QM&keywords=succeeding+with+agile&qid=1675874006&s=audible&sprefix=succeeding+with+agile%2Caudible%2C121&sr=1-1-catcorr',
		},
		{
			title: 'Rethinking AGILE',
			name: 'BUY on AMAZON',
			link: 'https://www.amazon.com/-/es/Klaus-Leopold-ebook/dp/B07XGQD4VH/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3UTI8KOQ96KXO&keywords=rethinking+agile&qid=1675874028&sprefix=rethinking+agile%2Caps%2C130&sr=8-1',
		},
		{
			title: 'Team Topologies',
			name: 'BUY on AMAZON',
			link: 'https://www.amazon.com/-/es/Matthew-Skelton/dp/1942788819/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1SRHPDGZZIGDP&keywords=team+topologies&qid=1675874045&sprefix=team+topologie%2Caps%2C129&sr=8-1',
		},
		{
			title: 'Enterprise agile coaching',
			name: 'BUY on AMAZON',
			link: 'https://www.amazon.com/-/es/Cherie-Silas/dp/B09JBKS1FB/ref=sr_1_1?__mk_es_US=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=37DXNQ49KQ301&keywords=enterprise+agile+coaching&qid=1675874059&sprefix=enterprise+agile+coaching%2Caps%2C130&sr=8-1',
		},
	];
	return (
		<>
			<OnlyHeader />
			<Container className='mt--7' fluid>
				<Row>
					<div className='col'>
						<Card className='shadow mb-4'>
							<CardBody>
								<h1 className='courses-header-text'>
									<span>Study</span> materials for our{' '}
									<span>AgileGrade</span> Assessment
								</h1>
								<Row>
									{books.map(book => (
										<Col
											className='mb-4 course-col'
											sm={6}
											md={12}
											lg={6}
											xl={4}
										>
											<CourseCard {...book}/>
										</Col>
									))}
								</Row>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Courses;
