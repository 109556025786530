import { Link, useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { signUpGoogle } from "store/action/authActions";
import Logo from "../../assets/img/Logo.png";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { register } from "store/action/authActions";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.auth);

  let [credentials, setCredential] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    isWelcomeShown: false,
    profile_image: "",
    type: "email",
  });

  const handleOnChange = (key, val) => {
    setCredential({ ...credentials, [key]: val });
  };

  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 auth-form">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted">
              <img src={Logo} alt="" width="20%" />
            </div>
            <hr className="mb-2 mt-3" />
            <div className="text-center text-muted mb-2">
              <h4>Sign Up</h4>
            </div>

            <Form
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
                if (password != confirmPassword) {
                  toast.warning("Password & Confirm passwrod not matched!");
                } else {
                  dispatch(register(credentials, password, history));
                }
              }}
            >
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name"
                    type="text"
                    value={credentials.first_name}
                    onChange={(e) =>
                      handleOnChange("first_name", e.target.value)
                    }
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Last Name"
                    type="text"
                    value={credentials.last_name}
                    onChange={(e) =>
                      handleOnChange("last_name", e.target.value)
                    }
                    required
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={credentials.email}
                    onChange={(e) => handleOnChange("email", e.target.value)}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Phone No"
                    type="text"
                    value={credentials.phone}
                    onChange={(e) => handleOnChange("phone", e.target.value)}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="w-100 border  rounded-pill"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Register"}
                </Button>
              </div>
            </Form>
            <Row className="mt-4 justify-content-end">
              <Col className="text-right" xs="6">
                <Link to="/auth/login">
                  <small>Already have an account?</small>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
