const initialState = {
  userPayment: {},
};
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PAYMENT":
      return {
        ...state,
        userPayment: action.payload,
      };

    default:
      return state;
  }
};
export default paymentReducer;
