import firebase from "firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const paymentCollection = firebase.firestore().collection("user_payments");

export const getUserPayments =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    paymentCollection.where("user_id", "==", id).onSnapshot((query) => {
      let tempPayments = [];
      if (query.docs.length > 0) {
        query.docs.forEach((doc) => {
          tempPayments.push({ id: doc.id, ...doc.data() });
        });
        dispatch({ type: "GET_USER_PAYMENT", payload: { ...tempPayments[0] } });
        onSuccess({ ...tempPayments[0] });
      } else {
        console.log("Else finally");
        dispatch({ type: "GET_USER_PAYMENT", payload: {} });
        onSuccess({});
      }
    });
  };

export const createPayment =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    paymentCollection
      .add({
        ...payload,
        pay_at: firebase.firestore.Timestamp.now(),
      })
      .then((err) => {
        toast.success(
			'Congratulations! You have 1 AgileGrade Assessment Attempt.',
			{
				autoClose: false,
				className: 'payment-toast',
				position: 'top-center',
			}
		);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

export const deletePayment = (id) => async (dispatch) => {
  paymentCollection.doc(id).delete();
};
