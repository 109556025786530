import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
} from "reactstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";

import { v4 as uuidv4 } from "uuid";

import ShareLink from "react-linkedin-share-link";

import CongImg from "../assets/img/badge.png";
import Badge0 from "../assets/img/badge/0.png";
import Badge1 from "../assets/img/badge/1.png";
import Badge2 from "../assets/img/badge/2.png";
import Badge3 from "../assets/img/badge/3.png";
// import Badge4 from '../assets/img/badge/4.png';
// import Badge5 from '../assets/img/badge/5.png';
// import Badge6 from '../assets/img/badge/6.png';
// import Badge7 from '../assets/img/badge/7.png';
// import Badge8 from '../assets/img/badge/8.png';
// import Badge9 from '../assets/img/badge/9.png';
import {
  getLeaderBoard,
  getQuestions,
  getUsersSubmission,
  resetSubmission,
  getMyScore,
  addBadge,
  getBadges,
  setBadgeLoading,
  removeBadges,
} from "../store/action/submitQuestionsAction";
import { getAllUsers } from "store/action/userActions";
import Continue from "components/Continue";

import LinkedIn from "../assets/img/LinkedIn.png";
import moment from "moment";

import axios from "axios";
import { getUserPayments } from "store/action/paymentActions";
import Paypal from "components/Paypal";
import { price } from "const";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const MyScore = () => {
  let [activeTab, setActiveTab] = useState("2");
  const { user } = useSelector((state) => state.auth);
  let [resetConfirmation, setResetConfirmation] = useState(false);
  const [levelUpModal, setLevelUpModal] = useState(false);
  const [claimBadgeModal, setClaimBadgeModal] = useState(false);
  const { userPayment } = useSelector((state) => state.payment);
  const levelUpToggle = () => {
    setLevelUpModal(!levelUpModal);
  };
  const badgeClaimToggle = () => {
    setClaimBadgeModal(!claimBadgeModal);
  };

  const location = useLocation();
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  let [level, setLevel] = useState(4);
  const [submitModel, setSubmitModel] = useState(false);
  const getBestGrade = () => {
    let scores = positions.map((position) => position.score);

    let maxScore = Math.max(...scores);
    return (maxScore / totalQuestions) * 100;
  };
  const getBadgeImage = (level) => {
    console.log(level);
    switch (Number(level)) {
      case 0:
        return Badge0;
      case 1:
        return Badge1;
      case 2:
        return Badge2;
      case 3:
        return Badge3;
      // case 4:
      // 	return Badge4;
      // case 5:
      // 	return Badge5;
      // case 6:
      // 	return Badge6;
      // case 7:
      // 	return Badge7;
      // case 8:
      // 	return Badge8;
      // case 9:
      // 	return Badge9;
    }
  };
  const dispatch = useDispatch();
  const {
    positions,
    leaderBoardLoading,
    submissions,
    questions,
    questionsLoading,
    myScore,
    badgeLoading,
    badges,
    getBadgesLoading,
    totalQuestions,
  } = useSelector((state) => state.questions);
  const { users, usersLoading } = useSelector((state) => state.user);

  const [paymentModal, setPaymentModal] = useState(false);
  const [rePayModal, setRePayModa] = useState(false);

  const paymentToggle = () => {
    setPaymentModal(!paymentModal);
  };
  const rePayToggle = () => {
    setRePayModa(!rePayModal);
  };

  const badgeId = uuidv4();

  const getGradeText = () => {
    return getMyPosition(user.id) == ""
      ? "loading"
      : getGrade(
          (getMyPosition(user.id).score /
            getMyPosition(user.id).answers_submitted.length) *
            100
        ) == ""
      ? "F"
      : getGrade(
          (getMyPosition(user.id).score /
            getMyPosition(user.id).answers_submitted.length) *
            100
        );
  };

  const getUserData = (id) => {
    if (users && users.length > 0) {
      let obj = users.find((user) => user.id == id);
      if (obj) {
        return obj.first_name + " " + obj.last_name;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };
  const getMyPosition = (id) => {
    if (positions && positions.length > 0) {
      let obj = "";
      positions.forEach((pos, idx) => {
        if (pos.id == id) {
          obj = { ranking: idx + 1, ...pos };
        }
      });
      console.log({ obj });
      return obj;
    } else {
      return "";
    }
  };
  const getGradeBadge = (submission) => {
    if (
      Object.keys(submission).length > 0 &&
      submission.answers_submitted &&
      submission.answers_submitted.length > 0
    ) {
      let percent = (submission.score * 100) / totalQuestions;
      switch (true) {
        case percent == 100:
          return Badge0;
        case percent >= 90:
          return Badge1;
        case percent >= 80:
          return Badge2;
        case percent >= 70:
          return Badge3;
        default:
          return "";
      }
    } else {
      return "";
    }
  };
  const getGrade = (percent) => {
    console.log(percent);
    switch (true) {
      case percent == 100:
        return "A+";
      case percent >= 90:
        return "A";
      case percent >= 80:
        return "B";
      case percent >= 70:
        return "C";
      default:
        return "";
    }
  };
  const [userGrade, setUserGrade] = useState("F");

  useEffect(() => {
    console.log({ location });

    // dispatch(getUserPayments(user.id));

    if (users && users.length == 0) {
      dispatch(getAllUsers());
    }
    if (positions && positions.length == 0) {
      dispatch(getLeaderBoard());
    }

    if (questions && questions.length == 0) {
      dispatch(getQuestions());
    }

    if (Object.keys(submissions).length === 0) {
      dispatch(getUsersSubmission(user.id));
    }
    if (myScore && Object.keys(myScore).length == 0) {
      dispatch(getMyScore(user.id));
    }
    if (badges && badges.length == 0) {
      dispatch(getBadges(user.id));
    }
  }, []);

  useEffect(() => {
    console.log({ submission: query.get("submission") });
    console.log({ level: query.get("level") });
    if (query.get("payment")) paymentToggle();
    else {
      if (query.get("level")) setLevel(query.get("level"));
      if (query.get("submission"))
        setSubmitModel(query.get("submission") == "completed");
    }
  }, [query]);
  let percentageValue = 0;
  let [badgePercentage, setBadgePercentage] = useState(0);
  useEffect(() => {
    percentageValue = percentageValue + 10;
  }, [badgePercentage]);
  const saveBadge = (level) => {
    setBadgePercentage(5);
    dispatch(setBadgeLoading(true));
    const input = document.getElementById("badgeToSave");
    const inputParent = document.getElementById("badgeToSaveParent");
    document.body.style.overflow = "hidden";
    inputParent.style.display = "block";
    setBadgePercentage(15);
    html2canvas(input, {
      // backgroundColor: null
    })
      .then(async (canvas) => {
        setBadgePercentage(30);
        inputParent.style.display = "none";
        document.body.style.overflow = "auto";
        var arr = canvas.toDataURL().split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        setBadgePercentage(35);
        dispatch(
          addBadge(
            new File([u8arr], "badge.png", { type: mime }),
            level,
            user.id,
            badgeId,
            async (imageURL) => {
              if (levelUpModal) {
                // levelUpToggle();
              }
              let currentGrade = await getUserNewGrade(currentLevel);
              // badgeClaimToggle();

              axios.post("https://agile-staging.herokuapp.com/api/sendMail", {
                user: user,
                imageUrl: imageURL,
                score: myScore.score,
                totalQuestions,
                resetLink: "https://agilegrade.com/admin/buy-cert",
                grade: currentGrade,
                generatedAt: moment(new Date()).format("DD/MM/YYYY"),
              });

              setBadgePercentage(100);
              dispatch(setBadgeLoading(false));
              setBadgePercentage(0);
            },
            (per) => {
              setBadgePercentage(per);
            }
          )
        );
      })
      .catch((error) => {
        dispatch(setBadgeLoading(false));
      });
  };

  const setUserNewGrade = (level) => {
    switch (Number(level)) {
      case 0:
        setUserGrade("A+");
        break;
      case 1:
        setUserGrade("A");
        break;
      case 2:
        setUserGrade("B");
        break;
      case 3:
        setUserGrade("C");
        break;
      default:
        setUserGrade("");
        break;
    }
  };
  const getUserNewGrade = (level) => {
    switch (Number(level)) {
      case 0:
        return "A+";
      case 1:
        return "A";
      case 2:
        return "B";
      case 3:
        return "C";
      default:
        return;
    }
  };

  const getPercentageByLevel = (level) => {
    switch (Number(level)) {
      case 0:
        return 100;
      case 1:
        return 90;
      case 2:
        return 80;
      case 3:
        return 70;
      default:
        return;
    }
  };

  const getCurrentLevelBadge = (currentLvl) => {
    if (badges?.length > 0) {
      let obj = badges.find(
        (badge) => badge.userId == user.id && badge.level == currentLvl
      );
      if (obj) {
        return obj;
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  const [currentLevel, setCurrentLevel] = useState(7);

  const checkCurrentLevel = () => {
    if (
      Object.keys(submissions).length > 0 &&
      submissions.answers_submitted &&
      submissions.answers_submitted.length > 0
    ) {
      // let questionLength = questions.length;
      let percent = (submissions.score * 100) / totalQuestions;
      switch (true) {
        case percent == 100:
          return 0;
        case percent >= 90:
          return 1;
        case percent >= 80:
          return 2;
        case percent >= 70:
          return 3;
        default:
          return;
      }
    } else {
      return;
    }
  };

  const downloadBadge = (url) => {
    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Badge.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    let level = query.get("level");

    if (
      !isNaN(level) &&
      !isNaN(currentLevel) &&
      level == currentLevel &&
      !levelUpModal &&
      !getBadgesLoading
    ) {
      setUserNewGrade(level);
      if (Object.keys(getCurrentLevelBadge(currentLevel)).length == 0) {
        saveBadge(currentLevel);
      }
    }
  }, [location, submissions, currentLevel, badges, getBadgesLoading]);

  useEffect(() => {
    setCurrentLevel(checkCurrentLevel());
  }, [submissions]);

  return (
		<>
			<OnlyHeader />
			{/* Page content */}
			<Container className='mt--7' fluid>
				{/* Table */}

				<Row>
					<div className='col'>
						<Card className='shadow mb-4'>
							<CardHeader className='border-0'>
								<div className='d-flex position-relative'>
									{!badgeLoading &&
										getCurrentLevelBadge(currentLevel)
											.imageURL && (
											<div className='d-flex flex-column flex-lg-row'>
												<Button
													className='mr-auto bg-danger text-white'
													size='sm '
													onClick={async () => {
														downloadBadge(
															getCurrentLevelBadge(
																currentLevel
															).imageURL
														);
													}}
												>
													Download
												</Button>

												<ShareLink
													link={`${
														getCurrentLevelBadge(
															currentLevel
														)?.imageURL
													}`}
												>
													{link => (
														<a
															href={link}
															target='_blank'
															style={{
																color: 'black',
																fontWeight:
																	'600',
															}}
															className='d-flex justify-content-center align-items-center ml-0 ml-lg-3 mt-3 mt-lg-0'
														>
															Share this on
															<img
																src={LinkedIn}
																className='ml-1'
																fluid
																width='15px'
															/>
														</a>
													)}
												</ShareLink>
											</div>
										)}

									<div
										className='position-absolute'
										style={{
											top: '50%',
											left: '50%',
											transform: 'translate(-50%,-50%)',
										}}
									>
										<h3 className='mb-0'>Buy Cert</h3>
									</div>

									{questions.length > 0 &&
										getGradeText() != 'loading' &&
										getGradeText() != 'A+' &&
										Object.keys(submissions).length > 0 &&
										submissions.answers_submitted &&
										totalQuestions <=
											submissions.answers_submitted
												.length && (
											<Button
												className='bg-danger text-white border-0 ml-auto d-flex justify-content-center align-items-center'
												size='sm'
												style={{ height: '28px' }}
												onClick={() =>
													setResetConfirmation(true)
												}
											>
												Reset
											</Button>
										)}
								</div>
							</CardHeader>
							{usersLoading ||
							leaderBoardLoading ||
							questionsLoading ||
							badgeLoading ? (
								<div className='p-5 d-flex flex-column justify-content-center align-items-center'>
									<Spinner size='lg' />{' '}
									{badgeLoading && badgePercentage != 0 && (
										<div className='d-flex flex-column justify-content-center align-items-center'>
											<span>Generating Badge</span>
											<span>{badgePercentage}%</span>
										</div>
									)}
								</div>
							) : (
								<>
									{/* <Nav tabs className='pl-3'>
										<NavItem className='cursor-pointer'>
											<NavLink
												className={classnames({
													active: activeTab === '1',
												})}
												onClick={() => {
													setActiveTab('1');
												}}
											>
												Global Positions
											</NavLink>
										</NavItem>
										<NavItem className='cursor-pointer'>
											<NavLink
												className={classnames({
													active: activeTab === '2',
												})}
												onClick={() => {
													setActiveTab('2');
												}}
											>
												My Position
											</NavLink>
										</NavItem>
									</Nav> */}

									{positions &&
									positions.length > 0 &&
									getMyPosition(user.id) == '' ? (
										<Continue />
									) : getGradeBadge(getMyPosition(user.id)) ==
									  '' ? (
										<Continue />
									) : (
										<div className='d-flex justify-content-center mb-4 badge__main px-2 px-md-4'>
											{getCurrentLevelBadge(currentLevel)
												.imageURL && (
												<img
													width={'100%'}
													src={
														getCurrentLevelBadge(
															currentLevel
														).imageURL
													}
													className='cursor-pointer'
												/>
											)}
										</div>
									)}
								</>
							)}

							{/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
						</Card>
					</div>
				</Row>

				<Modal
					isOpen={resetConfirmation}
					toggle={() => {
						setSubmitModel(false);
						setResetConfirmation(prevState => !prevState);
						history.push('/admin/buy-cert');
					}}
					centered
				>
					<ModalHeader
						toggle={() => {
							setSubmitModel(false);
							setResetConfirmation(prevState => !prevState);
						}}
					>
						<h4 className='mt-1 font-weight-bold'>Reset Score</h4>
					</ModalHeader>
					<hr className='m-0'></hr>
					<ModalBody>
						Are you sure you want to reset your score and start
						again your agilegrade journey?
					</ModalBody>
					<hr className='m-0'></hr>
					<ModalFooter>
						<Button
							className='outlined-dark'
							onClick={() => setResetConfirmation(false)}
						>
							Cancel
						</Button>
						<Button
							color='success'
							onClick={async e => {
								setSubmitModel(false);
								setResetConfirmation(false);
								rePayToggle();
								// await dispatch(resetSubmission(user.id));
								// await dispatch(removeBadges(badges, user.id));
								// await dispatch(getQuestions());
								// setResetConfirmation(false);
								// history.push("/admin/my-certs");
							}}
						>
							Reset
						</Button>
					</ModalFooter>
				</Modal>
				<div
					style={{
						backgroundColor: '#fafafa',
						marginTop: '500px',
						display: 'none',
					}}
					id='badgeToSaveParent'
					// className={`${levelUpModal ? 'd-block' : 'd-none'}`}
				>
					<div
						id='badgeToSave'
						style={{ zIndex: '-1' }}
						className='certificate'
					>
						<Row className='p-3'>
							<Col
								xs='12'
								className='text-center certificate__badge'
							>
								<img
									className='my-3'
									style={{ width: '1050px' }}
									src={getBadgeImage(currentLevel)}
									fluid
								/>
							</Col>
							<Col
								xs='12'
								className='text-center certificate__description'
							>
								<h1 className='text-center'>
									{user.first_name} {user.last_name}
								</h1>
								<h2 style={{ fontWeight: '700' }}>
									{user.first_name} {user.last_name} has
									answered correctly{' '}
									{getPercentageByLevel(currentLevel)}
									{/* {Math.floor(
										((myScore?.score ? myScore.score : 0) *
											100) /
											myScore?.answers_submitted?.length
									)} */}
									% of his assessment in AgileGrade.com and
									has achieved {getUserNewGrade(currentLevel)}{' '}
									Grade!
									<br /> Generated at{' '}
									{moment(new Date()).format(
										'DD/MM/YYYY'
									)}{' '}
									{moment().format('LT')}
								</h2>
							</Col>

							<Col
								xs='12'
								className='text-center mt-2 certificate__qr'
							>
								<h2 className='mb-0'>Scan to verify</h2>
								<QRCode
									value={`https://agilegrade.com/verify/badge/${badgeId}`}
									size='400'
								/>
							</Col>
							{/* <Col
								xs='12'
								className='d-flex justify-content-end  certificate__generated'
							>
								<h1>
									Generated at:{' '}
									{moment(new Date()).format('DD/MM/YYYY')}
								</h1>
							</Col> */}
						</Row>
					</div>
				</div>
			</Container>
			{/* Level Up Modal */}
			<Modal
				className=''
				isOpen={levelUpModal}
				toggle={() => {
					levelUpToggle();
					let url = '/admin/buy-cert';
					if (query.get('submission') == 'completed') {
						url += '?submission=completed';
					}
					history.push(url);
				}}
				size='md'
				centered
			>
				<ModalBody>
					<div className='text-center'>
						<img
							className='mb-1'
							src={getBadgeImage(level)}
							style={{
								width: '60%',
							}}
							fluid
						/>

						<h2>Congratulations!</h2>
						<p className='mb-1'>
							Level Up! You are now a{' '}
							<b className='font-weight-bold'>{userGrade}</b>
						</p>
					</div>
					<Container className='mt-4' fluid>
						{level != 0 && (
							<Row className='justify-content-center'>
								<Col className='mt-3 mt-xl-0'>
									<Button
										className='w-100'
										color='success'
										// disabled={badgeLoading}
										onClick={() => {
											// levelUpToggle();
											let url = '/admin/buy-cert';
											if (
												query.get('submission') ==
												'completed'
											) {
												url += '?submission=completed';
											}
											query.get('submission') ==
											'completed'
												? history.push(url)
												: history.push(
														'/admin/start-assessment'
												  );
										}}
									>
										{query.get('submission') == 'completed'
											? 'Try again for a better grade'
											: 'Continue answering to increase your grade'}
									</Button>
								</Col>

								<Col className='mt-3 mt-xl-0'>
									<Button
										className='w-100'
										color='danger'
										// disabled={badgeLoading}
										onClick={() => {
											levelUpToggle();
										}}
									>
										Claim and share your certification
									</Button>
								</Col>
							</Row>
						)}
					</Container>
				</ModalBody>
			</Modal>
			{/* Badge Claim Modal */}
			<Modal
				className=''
				isOpen={claimBadgeModal}
				toggle={() => {
					badgeClaimToggle();
				}}
				size='lg'
				centered
			>
				<ModalBody>
					<div className='d-flex mb-2'>
						<Button
							className='mr-auto bg-danger text-white'
							size='sm '
							onClick={async () => {
								let imgUrl = await getCurrentLevelBadge(
									currentLevel
								).imageURL;

								downloadBadge(imgUrl);
							}}
						>
							Download
						</Button>

						<ShareLink
							link={`${
								getCurrentLevelBadge(currentLevel)?.imageURL
							}`}
						>
							{link => (
								<a
									href={link}
									target='_blank'
									style={{
										color: 'black',
										fontWeight: '600',
									}}
									className='d-flex justify-content-center align-items-center'
								>
									Share this on
									<img
										src={LinkedIn}
										className='ml-1'
										fluid
										width='15px'
									/>
								</a>
							)}
						</ShareLink>
					</div>
					<div className='text-center'>
						{Object.keys(getCurrentLevelBadge(currentLevel))
							.length > 0 ? (
							<>
								<img
									className='mb-1'
									// src={getBadgeImage(level)}
									src={
										getCurrentLevelBadge(currentLevel)
											?.imageURL
									}
									style={{
										width: '100%',
									}}
									fluid
								/>
							</>
						) : (
							<h2>No badge available for current grade</h2>
						)}
					</div>
				</ModalBody>
			</Modal>
			{/* SubmitModel */}
			<Modal
				className=''
				isOpen={submitModel}
				toggle={() => setSubmitModel(prevState => !prevState)}
				size='md'
				centered
			>
				<ModalBody>
					{getGradeText() == 'loading' ? (
						<div className='d-flex align-items-center justify-content-center'>
							<Spinner size='sm' />
						</div>
					) : (
						<>
							<div className='text-center py-3'>
								<p className='mb-1'>
									<h2>Submission Completed!</h2>
									{currentLevel != 0 ? (
										<>
											{getGradeText() == 'F' ? (
												<>
													You have answered all
													questions and you got an "
													{getGradeText()}". Try again
													for a better Grade and to
													get Certified
												</>
											) : (
												<>
													You have answered all
													questions and you got an{' '}
													<b>"{getGradeText()}"</b>.
													If you want to improve your
													agile grade hit "Try again
													for a better Grade" to start
													over! Good Luck!
												</>
											)}
										</>
									) : (
										<>
											Great you got an{' '}
											<b>"{getGradeText()}"</b>. Claim
											your certificate
										</>
									)}
								</p>
							</div>

							<div className='d-flex justify-content-center'>
								{/* <Button
									className=''
									color='success'
									onClick={() => {
										setSubmitModel(prevState => !prevState);
										history.push('/admin/my-certs');
									}}
								>
									Got it
								</Button> */}
								{getGradeText() != 'loading' &&
								getGradeText() != 'A+' ? (
									<>
										<Button
											className=''
											color='danger'
											onClick={() =>
												setResetConfirmation(true)
											}
										>
											Try again for a better Grade
										</Button>
									</>
								) : (
									<></>
								)}
								{getGradeText() != 'loading' &&
									getGradeText() != 'F' && (
										<Button
											className=''
											color='success'
											onClick={() => {
												setSubmitModel(false);
												history.push('/admin/buy-cert');
											}}
										>
											Claim your certificate
										</Button>
									)}
							</div>
						</>
					)}
				</ModalBody>
			</Modal>

			<Modal
				isOpen={paymentModal}
				toggle={paymentToggle}
				centered
				size='lg'
				className='payment-modal'
			>
				<ModalHeader className='mb-0 pb-0 d-flex justify-content-center align-items-center'>
					<h1 className='font-weight-bold'>
						Prove Your Knowledge of Agile.
					</h1>

					{/* <h2 className="text-center">
            Greetings! Please pay 100$ to proceed.
          </h2> */}
				</ModalHeader>
				<ModalBody>
					<p className='mb-0'>
						The AgileGrade certification validates your knowledge of
						agile practices, values and the most important
						frameworks.
					</p>
					<h1 className='font-weight-bold'>
						Are you ready to show the world your agile expertise?
					</h1>
					<ul>
						<li>BadgeVerification USD per attempt</li>
						<li>
							Passing score:
							<ul>
								<li>
									Answer {`>`} 70% correctly to get a "C"
									grade Certification
								</li>
								<li>
									Answer {`>`} 80% correctly to get a "B"
									grade Certification
								</li>
								<li>
									Answer {`>`} 90% correctly to get a "A"
									grade Certification
								</li>
								<li>
									Answer 100% correctly to get an "A+" grade
									Certification
								</li>
							</ul>
						</li>

						<li>Time limit: 15 Seconds to answer each question</li>
						<li>Number of Questions: 100</li>
						<li>Format: Multiple Choice, True/False</li>
						<li>
							Digital AgileGrade Badge and Oficial Certification
						</li>
						<li>
							Lifetime certification - no annual renewal fee
							required
						</li>
					</ul>

					<Row>
						<Col md='6'>
							<Paypal paymentToggle={paymentToggle} />{' '}
						</Col>
						<Col md='6' className='mt-2 mt-md-0 '>
							<Button
								tag={Link}
								to='/admin/courses'
								color='success'
								className='w-100'
							>
								Preparation Materials
							</Button>
						</Col>
					</Row>
				</ModalBody>
				{/* <ModalFooter className="border-0 ">
          <Paypal paymentToggle={paymentToggle} />{" "}
          <Button className="" color="success">
            Preparation Materials
          </Button>
        </ModalFooter> */}
			</Modal>

			<Modal
				isOpen={rePayModal}
				toggle={rePayToggle}
				centered
				size='lg'
				className='payment-modal'
			>
				<ModalHeader className='mb-0 pb-0 d-flex justify-content-center align-items-center'>
					{/* <h2 className="text-center">
            Welcome back! Please pay 100$ to reset to submit answers.
          </h2> */}
					<h1 className='font-weight-bold'>
						Prove Your Knowledge of Agile.
					</h1>
				</ModalHeader>

				<ModalBody>
					<p className='mb-0'>
						{' '}
						The AgileGrade certification validates your knowledge of
						agile practices, values and the most important
						frameworks.
					</p>
					<h1 className='font-weight-bold'>
						Are you ready to show the world your agile expertise?
					</h1>
					{/* <br /> */}
					<ul>
						<li>Donate {price} USD per attempt</li>
						<li>
							Passing score:
							<ul>
								<li>
									Answer {`>`} 70% correctly to get a "C"
									grade Certification
								</li>
								<li>
									Answer {`>`} 80% correctly to get a "B"
									grade Certification
								</li>
								<li>
									Answer {`>`} 90% correctly to get a "A"
									grade Certification
								</li>
								<li>
									Answer 100% correctly to get an "A+" grade
									Certification
								</li>
							</ul>
						</li>

						<li>Time limit: 15 Seconds to answer each question</li>
						<li>Number of Questions: 100</li>
						<li>Format: Multiple Choice, True/False</li>
						<li>
							Digital AgileGrade Badge and Official Certification
						</li>
						<li>
							Lifetime certification - no annual renewal fee
							required
						</li>
					</ul>

					<Row>
						<Col md='6'>
							<Paypal
								paymentToggle={rePayToggle}
								type='reset'
								resetData={async () => {
									await dispatch(resetSubmission(user.id));
									await dispatch(
										removeBadges(badges, user.id)
									);
									await dispatch(getQuestions());
								}}
							/>
						</Col>
						<Col md='6' className='mt-2 mt-md-0 '>
							<Button
								tag={Link}
								to='/admin/courses'
								color='success'
								className='w-100'
							>
								Preparation Materials
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
  );
};

export default MyScore;
