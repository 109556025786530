import {
  Badge,
  Card,
  CardHeader,
  Container,
  Row,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getQuestions,
  submitAnswer,
  getUsersSubmission,
  submitQuestionsLoading,
  setUserGrade,
} from "store/action/submitQuestionsAction";
import { Markup } from "interweave";

import { useTimer } from "react-timer-hook";
import { Link, Redirect, useHistory } from "react-router-dom";
import CongImg from "../assets/img/badge.png";
import { changeWelcomeStatus } from "store/action/authActions";
import { getUserPayments } from "store/action/paymentActions";
import { price } from "const";

const SubmitQuestions = ({ expiryTimestamp }) => {
  const [questionNo, setQuestionNo] = useState(0);
  let [startAssessment, setStartAssessment] = useState(false);
  const [timeStamp, setTimeStamp] = useState(
    new Date().setSeconds(new Date().getSeconds() + 15)
  );

  const { userPayment } = useSelector((state) => state.payment);

  const dispatch = useDispatch();
  const {
    questions,
    loading: questionsLoading,
    submissions,
    grade,
    totalQuestions,
  } = useSelector((state) => state.questions);

  const history = useHistory();

  const { user } = useSelector((state) => state.auth);
  const [questionsData, setQuestionsData] = useState([]);

  const [welcomeModal, setWelcomeModal] = useState(false);

  const { seconds, restart, pause } = useTimer({
    expiryTimestamp: timeStamp,
    onExpire: async () => {
      if (!welcomeModal) {
        if (questionsData.length > 0 && !questionsLoading) {
          toast.error("Incorrect Answer!!!", { autoClose: 1500 });
          dispatch(submitQuestionsLoading(true));

          await dispatch(
            submitAnswer(
              {
                answer: "",
                question_id: questionsData[questionNo].id,
              },
              user.id,
              false
            )
          );
        }
      }
    },
  });

  useEffect(() => {
    pause();
  }, []);

  const handleAnswer = async (id, ans, correct_answer) => {
    dispatch(submitQuestionsLoading(true));
    let isCorrectAns = false;
    if (ans.toLowerCase() == correct_answer.toLowerCase()) {
      toast.success("Correct Answer!!!", { autoClose: 1500 });
      isCorrectAns = true;
    } else {
      toast.error("Incorrect Answer!!!", { autoClose: 1500 });
    }
    let prevLevel = submissions.level ? submissions.level : 3;
    let prevScore = submissions.score ? submissions.score : 0;

    await dispatch(
      submitAnswer(
        {
          answer: ans,
          question_id: id,
        },
        user.id,
        isCorrectAns,
        prevLevel,
        prevScore
      )
    );
  };

  const resetTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 15);
    restart(time);
  };
  useEffect(() => {
    if (startAssessment)
      window.setTimeout(() => {
        // dispatch(submitQuestionsLoading(false));
        resetTimer();
      }, 2000);
  }, [questionsData, startAssessment]);

  useEffect(() => {
    if (questions && questions.length == 0) {
      dispatch(getQuestions());
    }
  }, []);
  useEffect(() => {
    if (Object.keys(submissions).length === 0) {
      dispatch(
        getUsersSubmission(user.id, () => {
          if (startAssessment) resetTimer();
        })
      );
    }
  }, []);

  const calculateUserGrade = (score, totalSubmittedQuestions) => {
    let percent = (score * 100) / totalSubmittedQuestions;
    switch (true) {
      case percent == 100:
        return 0;
      case percent >= 90:
        return 1;
      case percent >= 80:
        return 2;
      case percent >= 70:
        return 3;
      default:
        return 4;
    }
  };

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  useEffect(() => {
    if (!isPaymentLoading) {
      if (
        submissions &&
        submissions.answers_submitted &&
        submissions.answers_submitted.length != 0
      ) {
        let newArr = [];
        newArr = questions.filter((question) => {
          if (
            !submissions.answers_submitted.find(
              (ans) => ans.question_id == question.id
            )
          ) {
            return question;
          }
        });
        let questionsToShow =
          totalQuestions - submissions.answers_submitted.length;

        setQuestionNo(0);
        setQuestionsData(newArr.slice(0, questionsToShow));

        let newGrade = calculateUserGrade(submissions.score, totalQuestions);

        // if (newGrade < grade) {
        // 	dispatch(setUserGrade(newGrade));
        // }
        // 	// let search = `level=${newGrade}`;
        // 	if (submissions.answers_submitted.length >= totalQuestions) {
        // 		search += '&submission=completed';
        // 	}
        // 	console.log({ search });
        // 	history.push({
        // 		pathname: '/admin/my-certs',
        // 		search,
        // 	});
        // } else
        if (submissions.answers_submitted.length >= totalQuestions) {
          let search = `submission=completed&level=${newGrade}`;
          history.push({
            pathname: "/admin/buy-cert",
            search,
          });
        }
      } else {
        setQuestionNo(0);
        setQuestionsData(questions.slice(0, totalQuestions));
        // dispatch(setUserGrade(4));
      }
    }
  }, [submissions, questions, isPaymentLoading]);

  const checkingPayment = () => {
    setIsPaymentLoading(true);
    dispatch(
      getUserPayments(user.id, (res) => {
        if (Object.keys(res).length == 0) {
          let search = `payment=false`;
          history.push({
            pathname: "/admin/buy-cert",
            search,
          });
        }
        setIsPaymentLoading(false);
      })
    );
    // setTimeout(function () {
    //   console.log("LOD", userPayment);
    //   if (Object.keys(userPayment).length == 0) {
    //     let search = `payment=false`;
    //     history.push({
    //       pathname: "/admin/buy-cert",
    //       search,
    //     });
    //   }
    //   setIsPaymentLoading(false);
    // }, 6000);
  };

  //   useEffect(() => {
  //     if (Object.keys(userPayment).length == 0) {
  //       let search = `payment=false`;
  //       history.push({
  //         pathname: "/admin/buy-cert",
  //         search,
  //       });
  //     }
  //   }, [userPayment]);

  useEffect(() => {
    // dispatch(getUserPayments(user.id));
    checkingPayment();
  }, []);

  return (
    <>
      <OnlyHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            {isPaymentLoading ? (
              <Card className="shadow p-3 mb-4 loading-container">
                <Spinner />
              </Card>
            ) : !startAssessment ? (
              <Card className="shadow py-3 px-2">
                <CardBody>
                  <p className="mb-0">
                    The AgileGrade certification validates your knowledge of
                    agile practices, values and the most important frameworks.
                  </p>
                  <h1 className="font-weight-bold">
                    Are you ready to show the world your agile expertise?
                  </h1>
                  <ul>
                    <li>Donate {price} USD per attempt</li>
                    <li>
                      Passing score:
                      <ul>
                        <li>
                          Answer {`>`} 70% correctly to get a "C" grade
                          Certification
                        </li>
                        <li>
                          Answer {`>`} 80% correctly to get a "B" grade
                          Certification
                        </li>
                        <li>
                          Answer {`>`} 90% correctly to get a "A" grade
                          Certification
                        </li>
                        <li>
                          Answer 100% correctly to get an "A+" grade
                          Certification
                        </li>
                      </ul>
                    </li>

                    <li>Time limit: 15 Seconds to answer each question</li>
                    <li>Number of Questions: 100</li>
                    <li>Format: Multiple Choice, True/False</li>
                    <li>Digital AgileGrade Badge and Oficial Certification</li>
                    <li>
                      Lifetime certification - no annual renewal fee required
                    </li>
                  </ul>
                  <div className="d-flex justify-content-center pt-4 align-items-center">
                    <Button
                      onClick={() => setStartAssessment(true)}
                      color="success"
                      size="md"
                    >
                      Start/Continue assessment
                    </Button>
                    <Button tag={Link} to="/admin/courses" color="dark" className="mx-2" size="md">
                      Study for assessment
                    </Button>
                    <Button color="outline-dark" size="md">
                      Come back later
                    </Button>
                  </div>
                </CardBody>
              </Card>
            ) : questionsLoading ? (
              <Card className="shadow p-3 mb-4 loading-container">
                <Spinner />
              </Card>
            ) : questionsData.length == 0 ? (
              <Card className="shadow p-3 mb-4 loading-container">
                <h3>There are no questions to answer yet!</h3>
              </Card>
            ) : (
              <>
                {questionsData.slice(questionNo, questionNo + 1).map((data) => {
                  return (
                    <>
                      <Card className="shadow p-3 mb-4" key={data.id}>
                        <CardHeader className="border-0">
                          <div className="d-flex justify-content-center">
                            <div className="bar-container">
                              {new Array(totalQuestions)
                                .fill()
                                .map((_, idx) => (
                                  <div
                                    className={`bar ${
                                      submissions &&
                                      submissions.answers_submitted &&
                                      submissions.answers_submitted[idx]
                                        ? submissions.answers_submitted[idx]
                                            .isCorrect
                                          ? "bg-success"
                                          : "bg-success"
                                        : ""
                                    }`}
                                  ></div>
                                ))}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0">
                              <b>
                                Question #{" "}
                                {submissions.answers_submitted
                                  ? submissions.answers_submitted.length + 1
                                  : 1}{" "}
                                out of {totalQuestions}
                              </b>
                            </h3>
                            {/* <div className="d-flex justify-content-center w-100 ">
                                <Button color="primary" onClick={toggleWelcome}>
                                  Welcome
                                </Button>
                                <Button color="dark" onClick={toggleCongrates}>
								   Congrates
								</Button>
                                <Button color="danger" onClick={toggleSubmit}>
									Model3
								</Button>
                              </div> */}
                            <span className="ml-auto">
                              <div className="timer">
                                {welcomeModal ? 15 : seconds}
                              </div>
                            </span>
                          </div>
                        </CardHeader>
                        <hr className="m-0" />
                        <CardBody>
                          <Badge color="primary" pill>
                            {data.category}
                          </Badge>
                          <h2
                            className="mt-2 question"
                            onClick={(e) => {
                              if (
                                e.target.tagName.toLowerCase() == "a" &&
                                e.target.href
                              ) {
                                pause();
                              }
                            }}
                          >
                            <Markup content={data.question} />
                          </h2>
                          {data.answers &&
                            data.answers.length > 0 &&
                            data.answers.map((ans, idx) => {
                              return (
                                <div className="custom-control custom-control-alternative custom-radio">
                                  <input
                                    className="custom-control-input"
                                    id={`Answer${idx + 1}`}
                                    name={`question${questionNo + 1}`}
                                    onClick={() => {
                                      handleAnswer(
                                        data.id,
                                        ans,
                                        data.correct_answer
                                      );
                                    }}
                                    type="radio"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`Answer${idx + 1}`}
                                  >
                                    <h4 className="text-muted">{ans}</h4>
                                  </label>
                                </div>
                              );
                            })}
                        </CardBody>

                        {/*Model Sections  */}

                        {/* Model End */}

                        {/* <CardFooter className='d-flex justify-content-between'>
													<Button
														color='info'
														disabled={
															questionNo == 0
														}
														onClick={() => {
															setQuestionNo(
																questionNo - 1
															);
														}}
													>
														Previous
													</Button>
													{questionNo ==
													questions.length - 1 ? (
														<Button
															color='info'
															onClick={() => {
																dispatch(
																	submitAnswer(
																		{
																			answers_submitted:
																				answers,
																			user_id:
																				user.id,
																		}
																	)
																);
															}}
														>
															Finish
														</Button>
													) : (
														<Button
															color='info'
															disabled={
																questionNo ==
																questions.length -
																	1
															}
															onClick={() => {
																setQuestionNo(
																	questionNo +
																		1
																);
															}}
														>
															Next
														</Button>
													)}
												</CardFooter> */}
                      </Card>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SubmitQuestions;
