import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Continue = () => {
	return (
		<div className='d-flex flex-column align-items-center justify-content-center p-4'>
			<h2>
				Please continue answering questions to earn a badge with your
				agility grade
			</h2>
			<Button
				to='/admin/start-assessment'
				tag={Link}
				color='success'
				className='mt-3'
			>
				Answer Questions <i className='fa fa-angle-right ml-2'></i>
			</Button>
		</div>
	);
};

export default Continue;
