import Login from "views/auth/Login.js";
import SubmitQuestions from "views/SubmitQuestions";
import LeaderBoard from "views/LeaderBoard";
import Logout from "views/Logout";
import Users from "views/Users";
import MyScore from "views/MyScore";
import Dashboard from "views/Dashboard";
import Register from "views/auth/Register";
import Courses from "views/Courses";
import Home from "views/Home";
import BadgeVerification from "views/BadgeVerification";
import ForgotPassword from "views/auth/ForgotPassowrd";

var routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/verify/badge/:id",
    name: "Dashboard",
    component: BadgeVerification,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-television text-dark",
    component: Dashboard,
    layout: "/admin",
    permission: ["admin"],
  },
  {
    path: "/buy-cert",
    name: "Buy Cert",
    icon: "fa fa-medal text-dark",
    component: MyScore,
    layout: "/admin",
    permission: ["admin", "user"],
  },
  {
    path: "/leader-board",
    name: "Leader Board",
    icon: "ni ni-bullet-list-67 text-red",
    component: LeaderBoard,
    layout: "/admin",
    permission: ["admin", "user"],
  },
  {
    path: "/start-assessment",
    name: "Start Assessment",
    icon: "ni ni-collection text-green",
    component: SubmitQuestions,
    layout: "/admin",
    permission: ["admin", "user"],
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "fa fa-book text-green",
    component: Courses,
    layout: "/admin",
    permission: ["admin", "user"],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out text-orange",
    component: Logout,
    layout: "/admin",
    permission: ["admin", "user"],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
