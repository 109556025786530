import { Link, useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { signUpGoogle } from "store/action/authActions";
import Logo from "../../assets/img/Logo.png";
import { useEffect } from "react";
import { useState } from "react";
import { forgotPassword } from "store/action/authActions";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("");

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 auth-form">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted">
              <img src={Logo} alt="" width="20%" />
            </div>
            <hr className="mb-2 mt-3" />
            <div className="text-center text-muted mb-2">
              <h4>Forgot Password</h4>
            </div>
            <Form
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                dispatch(
                  forgotPassword(email, () => {
                    setLoading(false);
                  })
                );
              }}
            >
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="w-100 border  rounded-pill"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading && type == "email" ? (
                    <Spinner size="sm" />
                  ) : (
                    "Forgot Password"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
