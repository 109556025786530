/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// reactstrap components
import {
	Button,
	Collapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/action/authActions';
import Logo from '../../assets/img/Logo.png';

var ps;

const Sidebar = props => {
	const dispatch = useDispatch();
	const [collapseOpen, setCollapseOpen] = useState();
	const { user } = useSelector(state => state.auth);
	// verifies if routeName is the one active (in browser input)
	const activeRoute = routeName => {
		return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	};
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen(data => !data);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	// creates the links that appear in the left menu / Sidebar
	const createLinks = routes => {
		let role = user?.role || 'user';
		return routes.map((prop, key) => {
			if (prop.isMenu != false)
				if (prop.permission.includes(role)) {
					return (
						<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName='active'
							>
								<i className={prop.icon} />
								{prop.name}
							</NavLink>
						</NavItem>
					);
				}
		});
	};

	const { bgColor, routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}

	return (
		<Navbar
			className='navbar-vertical fixed-left navbar-light bg-white'
			expand='md'
			id='sidenav-main'
		>
			<Container fluid>
				{/* Toggler */}
				<button
					className='navbar-toggler'
					type='button'
					onClick={toggleCollapse}
				>
					<span className='navbar-toggler-icon' />
				</button>
				{/* Brand */}
				{logo ? (
					<>
						<NavbarBrand
							className='pt-0 font-weight-bold d-block d-md-none'
							{...navbarBrandProps}
						>
							{/* <img
							alt={logo.imgAlt}
							className='navbar-brand-img'
							src={logo.imgSrc}
						/> */}{' '}
							<img src={Logo} alt='' className='mr-2' />
						</NavbarBrand>
						<div className='text-center d-none d-md-block'>
							<img
								src={Logo}
								alt=''
								className='mr-2 d-block mx-auto'
								style={{ width: '40%' }}
							/>
							<hr className='my-3' />
						</div>
					</>
				) : null}
				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className='navbar-collapse-header d-md-none'>
						<Row>
							{logo ? (
								<Col className='collapse-brand' xs='6'>
									{logo.innerLink ? (
										<Link
											to={logo.innerLink}
											className='font-weight-bold'
										>
											{/* <img alt={logo.imgAlt} src={logo.imgSrc} /> */}{' '}
											Agile Grade
										</Link>
									) : (
										<a
											href={logo.outterLink}
											className='font-weight-bold'
										>
											{/* <img alt={logo.imgAlt} src={logo.imgSrc} /> */}{' '}
											Agile Grade
										</a>
									)}
								</Col>
							) : null}
							<Col className='collapse-close' xs='6'>
								<button
									className='navbar-toggler'
									type='button'
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					<Nav navbar>{createLinks(routes)}</Nav>
				</Collapse>
				<div className='navbar-toggler'></div>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;
