import { combineReducers } from "redux";
import authReducer from "./authReducer";
import submitQuestionsReducer from "./submitQuestionsReducer";
import userReducer from "./userReducer";
import paymentReducer from "./paymentReducer";
export const appReducer = combineReducers({
  auth: authReducer,
  questions: submitQuestionsReducer,
  user: userReducer,
  payment: paymentReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
