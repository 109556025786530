import React, { useState } from 'react';

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import Logo from '../../assets/img/Logo.png';
const PublicHeader = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Navbar color='light' light expand='md' className='public-navbar'>
				<NavbarBrand className='mx-auto'>
					<img src={Logo} alt='' width='60' className='mx-auto' />
				</NavbarBrand>
			</Navbar>
		</>
	);
};

export default PublicHeader;
