/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
	Card,
	CardBody,
	CardTitle,
	Container,
	Row,
	Col,
	Spinner,
	Button,
} from 'reactstrap';
import { getAllUsers } from 'store/action/userActions';
import {
	getQuestions,
	importQuestions,
	setImportQuestionsLoading,
} from 'store/action/submitQuestionsAction';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const acceptedFileType = ['csv', 'xlsx', 'xls', 'XLS', 'CSV', 'XLSX'];

const Header = () => {
	const { users, usersLoading } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const { questions, questionsLoading, submissions, importQueLoading } =
		useSelector(state => state.questions);

	const inputFileRef = useRef(null);

	const checkFileType = impFile => {
		let fileCheck = false;

		let fileChk = impFile.name.split('.');
		let ext = fileChk[[fileChk.length - 1]];

		if (!acceptedFileType.includes(ext)) {
			fileCheck = true;
		}
		return fileCheck;
	};
	const processSheetData = excelData => {
		const wsname = excelData.SheetNames[0];
		const ws = excelData.Sheets[wsname];
		const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
		if (dataParse.length > 0) {
			if (
				dataParse[0][0] !== 'Question' ||
				dataParse[0][1] !== 'Category' ||
				dataParse[0][2] !== 'Answer A' ||
				dataParse[0][3] !== 'Answer B' ||
				dataParse[0][4] !== 'Answer C' ||
				dataParse[0][5] !== 'Correct Answer'
			) {
				toast.warning(
					'File Contain invalid data! Please upload correct file!'
				);
				dispatch(setImportQuestionsLoading(false));
				return;
			} else {
				let questionsData = [];
				for (let i = 1; i < dataParse.length; i++) {
					let obj = {
						question: '',
						category: '',
						correct_answer: '',
						answers: [],
					};
					if (dataParse[i][0] != undefined) {
						obj.question = dataParse[i][0];
						if (dataParse[i][1] != undefined) {
							obj.category = dataParse[i][1];
						}
						if (dataParse[i][2] != undefined) {
							obj.answers.push(dataParse[i][2]);
						}
						if (dataParse[i][3] != undefined) {
							obj.answers.push(dataParse[i][3]);
						}
						if (dataParse[i][4] != undefined) {
							obj.answers.push(dataParse[i][4]);
						}
						if (dataParse[i][5] != undefined) {
							switch (dataParse[i][5]) {
								case 'A' || 'a':
									if (dataParse[i][2] != undefined) {
										obj.correct_answer = dataParse[i][2];
									}
									break;
								case 'B' || 'b':
									if (dataParse[i][3] != undefined) {
										obj.correct_answer = dataParse[i][3];
									}
									break;
								case 'C' || 'c':
									if (dataParse[i][4] != undefined) {
										obj.correct_answer = dataParse[i][4];
									}
									break;
								default:
									break;
							}
						}
						questionsData.push(obj);
					}
				}
				if (questionsData.length > 0) {
					dispatch(importQuestions(questionsData));
				} else {
					dispatch(setImportQuestionsLoading(false));
					toast.warning('File is empty!');
				}
			}
		} else {
			toast.warning(
				'File Contain invalid data! Please upload correct file!'
			);
			dispatch(setImportQuestionsLoading(false));
			return;
		}
	};

	useEffect(() => {
		if (users && users.length == 0) {
			dispatch(getAllUsers());
		}

		if (questions && questions.length == 0) {
			dispatch(getQuestions());
		}
	}, []);
	return (
		<>
			<div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
				<Container>
					<div className='header-body'>
						{/* Card stats */}
						<Row>
							{usersLoading || questionsLoading ? (
								<Col
									xs='12'
									className='loading-container d-flex justify-content-center '
								>
									<Spinner color='white' />
								</Col>
							) : (
								<>
									<Col lg='6' xl='6'>
										<Card className='card-stats mb-4 mb-xl-0'>
											<CardBody>
												<Row>
													<div className='col'>
														<CardTitle
															tag='h5'
															className='text-uppercase text-muted mb-0'
														>
															USERS
														</CardTitle>
														<span className='h2 font-weight-bold mb-0'>
															{users.length}
														</span>
													</div>
													<Col className='col-auto'>
														<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
															<i className='fas fa-chart-bar' />
														</div>
													</Col>
												</Row>
												<CSVLink
													data={users.map(user => {
														return {
															profile_image:
																user.profile_image,
															first_name:
																user.first_name,
															last_name:
																user.last_name,
															email: user.email,
														};
													})}
													target='_blank'
													className='btn btn-success mt-2'
													filename='users_data'
													style={{
														padding: '5px 10px',
													}}
												>
													Export
												</CSVLink>
												{/* <p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												<i className='fa fa-arrow-up' />{' '}
												3.48%
											</span>{' '}
											<span className='text-nowrap'>
												Since last month
											</span>
										</p> */}
											</CardBody>
										</Card>
									</Col>
									<Col lg='6' xl='6'>
										<Card className='card-stats mb-4 mb-xl-0'>
											<CardBody>
												<Row>
													<div className='col'>
														<CardTitle
															tag='h5'
															className='text-uppercase text-muted mb-0'
														>
															QUESTIONS
														</CardTitle>
														<span className='h2 font-weight-bold mb-0'>
															{questions.length}
														</span>
													</div>
													<Col className='col-auto'>
														<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
															<i className='fas  fa-question-circle' />
														</div>
													</Col>
												</Row>
												<Button
													className='bg-success text-white border-0 mt-2'
													style={{
														padding: '5px 10px',
													}}
													onClick={e =>
														inputFileRef.current.click()
													}
													disabled={importQueLoading}
												>
													{importQueLoading ? (
														<Spinner size='sm'></Spinner>
													) : (
														'Import'
													)}
												</Button>
												<input
													type='file'
													id='file'
													ref={inputFileRef}
													onChange={e => {
														if (
															!checkFileType(
																e.target
																	.files[0]
															)
														) {
															dispatch(
																setImportQuestionsLoading(
																	true
																)
															);
															const reader =
																new FileReader();
															reader.onload =
																function (e) {
																	const data =
																		e.target
																			.result;
																	let readedData =
																		XLSX.read(
																			data,
																			{
																				type: 'binary',
																			}
																		);
																	processSheetData(
																		readedData
																	);
																};
															reader.readAsBinaryString(
																e.target
																	.files[0]
															);
														} else {
															toast.warning(
																'Please select only csv/xlsx file!'
															);
														}
													}}
													onClick={e =>
														(e.target.value = null)
													}
													hidden
												/>
												{/* <p className='mt-3 mb-0 text-muted text-sm'>
											<span className='text-success mr-2'>
												<i className='fa fa-arrow-up' />{' '}
												3.48%
											</span>{' '}
											<span className='text-nowrap'>
												Since last month
											</span>
										</p> */}
											</CardBody>
										</Card>
									</Col>
								</>
							)}
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Header;
