const initialState = {
	usersLoading: false,
	users: [],
};
const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case 'GET_ALL_USERS':
			return {
				...state,
				users: payload,
			};
		case 'SET_USERS_LOADING':
			return {
				...state,
				usersLoading: payload,
			};
		default:
			return state;
	}
};
export default userReducer;
