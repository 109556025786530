import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import LeftLogo from '../assets/img/leftLogo.png';

const Main = () => {
	return (
		<div className='main-header text-dark'>
			<Container className='main-header-container'>
				<Row className='align-items-center'>
					<Col lg='6'>
						<h1 className='main-header-text'>
							A simple <span>assessment</span> to showcase your{' '}
							<span>AGILE</span> knowledge
						</h1>
						<p className='main-header-description'>
							Answer 100 questions about agile to earn the coolest
							badge ever in a few minutes, then share it to the
							world (A.K.A. Your Linkedin network)
						</p>
						<Row className='mt-4'>
							<Col xs={6} lg={12} xl={6}>
								<Button
									tag={Link}
									to='/auth/login?ref=buy-cert'
									color='success'
									className='main-header-btn'
									block
								>
									Buy an attempt NOW
								</Button>
							</Col>
							<Col
								xs={6}
								lg={12}
								xl={6}
								className='mt-0 mt-sm-0 mt-lg-4 mt-xl-0'
							>
								<Button
									tag={Link}
									to='/auth/login?ref=courses'
									color='danger'
									className='main-header-btn'
									block
								>
									Study Materials
								</Button>
							</Col>
						</Row>
					</Col>
					<Col sm='8' lg='6' className='mx-auto mx-lg-0 mt-4 mt-lg-0'>
						<img src={LeftLogo} className='w-100' />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Main;
