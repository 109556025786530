/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
	useLocation,
	Route,
	Switch,
	Redirect,
	useHistory,
} from 'react-router-dom';
// reactstrap components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Admin = ({ children, ...props }) => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	const history = useHistory();
	const { user } = useSelector(state => state.auth);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	useEffect(() => {
		if (user === null) {
			history.push('/');
		}
	}, [user]);

	const getRoutes = routes => {
		let role = user?.role || 'user';
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				if (prop.permission.includes(role)) {
					return (
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				}
			} else {
				return null;
			}
		});
	};

	const getBrandText = path => {
		for (let i = 0; i < routes.length; i++) {
			if (
				location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				return routes[i].name;
			}
		}
		return 'Brand';
	};

	return (
		<>
			<Sidebar
				{...props}
				routes={routes}
				logo={{
					innerLink: '/admin/index',
					imgSrc: require('../assets/img/brand/argon-react.png')
						.default,
					imgAlt: '...',
				}}
			/>
			<div className='main-content' ref={mainContent}>
				<AdminNavbar
					{...props}
					brandText={getBrandText(location.pathname)}
				/>
				{children}
			</div>
		</>
	);
};

export default Admin;
