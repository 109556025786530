import firebase from "firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
export const submitQuestionsLoading = (val) => async (dispatch) => {
  dispatch({ type: "QUESTIONS_LOADING", payload: val });
};

export const setBadgeLoading = (val) => async (dispatch) => {
  dispatch({ type: "SET_BADGE_LOADING", payload: val });
};

export const getBadgesLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_BADGES_LOADING", payload: val });
};

export const setLeaderBoardLoading = (val) => async (dispatch) => {
  dispatch({ type: "SET_LEADER_BOARD_LOADING", payload: val });
};

export const setImportQuestionsLoading = (val) => async (dispatch) => {
  dispatch({ type: "SET_IMPORT_QUESTIONS_LOADING", payload: val });
};
export const importQuestions = (newQuestions) => async (dispatch) => {
  let questionsCollection = firebase.firestore().collection("questions");
  let prevQuestionsDocs = await questionsCollection.get();
  let updateQuestions = [];
  let newAddedQuestionsCount = 0;
  let updatedQuestionsCount = 0;
  let deletedQuestionsCount = 0;
  let prevQuestions = [];
  let queries = [];
  for (let doc of prevQuestionsDocs.docs) {
    prevQuestions.push({ id: doc.id, ...doc.data() });
  }
  if (prevQuestions.length == 0) {
    for (let newQuestion of newQuestions) {
      queries.push(questionsCollection.add(newQuestion));
    }
  } else {
    let questionToBeUpdated = [];
    let questionToBeDeleted = [];
    let questionToBeAdded = [];
    prevQuestions.forEach((prevQuestion) => {
      let newQuestion = newQuestions.find(
        (newQuestion) => newQuestion.question == prevQuestion.question
      );
      if (newQuestion) {
        questionToBeUpdated.push({
          id: prevQuestion.id,
          ...newQuestion,
        });
      } else {
        questionToBeDeleted.push({
          id: prevQuestion.id,
        });
      }
    });
    newQuestions.forEach((newQuestion) => {
      let prevQuestion = prevQuestions.find(
        (prevQuestion) => prevQuestion.question == newQuestion.question
      );
      if (prevQuestion == null) {
        questionToBeAdded.push({ ...newQuestion });
      }
    });

    for (let question of questionToBeDeleted) {
      let { id } = question;
      queries.push(questionsCollection.doc(id).delete());
    }
    for (let question of questionToBeUpdated) {
      let { id, ...data } = question;
      queries.push(questionsCollection.doc(id).update({ ...data }));
    }
    for (let question of questionToBeAdded) {
      let { ...data } = question;
      queries.push(questionsCollection.add({ ...data }));
    }
    console.log({
      questionToBeUpdated,
      questionToBeDeleted,
      questionToBeAdded,
    });
  }
  Promise.all(queries).then(() => {
    toast.success("Questions Imported Successfully!");
    dispatch(setImportQuestionsLoading(false));
  });
};
const shuffleArray = (arr) => arr.sort(() => 0.5 - Math.random());
export const getQuestions = () => async (dispatch) => {
  dispatch(submitQuestionsLoading(true));
  firebase
    .firestore()
    .collection("questions")
    .onSnapshot(async (data) => {
      let tempInvitations = [];
      for (let doc of data.docs) {
        tempInvitations.push({ id: doc.id, ...doc.data() });
      }
      tempInvitations = shuffleArray(tempInvitations);
      dispatch({ type: "GET_QUESTIONS", payload: tempInvitations });
      dispatch(submitQuestionsLoading(false));
    });
};

export const submitAnswer = (data, id, isCorrectAns) => async (dispatch) => {
  let submissionDoc = await firebase
    .firestore()
    .collection("submissions")
    .doc(id)
    .get();
  data["isCorrect"] = isCorrectAns;
  if (submissionDoc.exists) {
    let obj = {
      answers_submitted: firebase.firestore.FieldValue.arrayUnion(data),
    };
    if (isCorrectAns) {
      obj = { ...obj, score: firebase.firestore.FieldValue.increment(1) };
    }
    await firebase.firestore().collection("submissions").doc(id).update(obj);
  } else {
    let obj = {
      score: 0,
      answers_submitted: [data],
    };
    if (isCorrectAns) obj = { ...obj, score: 1 };
    await firebase.firestore().collection("submissions").doc(id).set(obj);
  }
};
export const getUsersSubmission =
  (id, resetTimer = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("submissions")
      .doc(id)
      .onSnapshot(async (doc) => {
        let tempUser = { id: doc.id, ...doc.data() };

        await dispatch({
          type: "GET_USERS_SUBMISSION",
          payload: tempUser,
        });
        setTimeout(() => {
          dispatch(submitQuestionsLoading(false));
          resetTimer();
        }, 2000);
      });
  };

export const getLeaderBoard = () => async (dispatch) => {
  dispatch(setLeaderBoardLoading(true));
  firebase
    .firestore()
    .collection("submissions")
    .orderBy("score", "desc")
    .onSnapshot(async (data) => {
      let submissionsData = [];
      for (let doc of data.docs) {
        // if (doc.data().answers_submitted.length >= questionsDoc.size) {
        submissionsData.push({ id: doc.id, ...doc.data() });
        // }
      }
      console.log({ submissionsData });
      dispatch({ type: "GET_LEADER_BOARD", payload: submissionsData });
      dispatch(setLeaderBoardLoading(false));
    });
};

export const getMyScore = (id) => async (dispatch) => {
  dispatch(setLeaderBoardLoading(true));
  firebase
    .firestore()
    .collection("submissions")
    .doc(id)
    .onSnapshot(async (data) => {
      let submissionsData = data.data();
      dispatch({ type: "GET_MY_SCORE", payload: submissionsData });
      dispatch(setLeaderBoardLoading(false));
    });
};

export const resetSubmission = (id) => async (dispatch) => {
  firebase.firestore().collection("submissions").doc(id).update({
    answers_submitted: [],
    score: 0,
  });
};

export const removeBadges = (badges, id) => async (dispatch) => {
  if (badges.length > 0 && id) {
    let userBadges = await badges.filter((badge) => badge.userId == id);
    userBadges.forEach(async (badge) => {
      if (badge.imageURL) {
        await firebase.storage().refFromURL(badge.imageURL).delete();
      }
      await firebase.firestore().collection("badges").doc(badge.id).delete();
    });
  }
};

export const setUserGrade = (grade) => async (dispatch) => {
  dispatch({ type: "SET_USER_GRADE", payload: grade });
};

export const getBadges = (userId) => async (dispatch) => {
  await dispatch(getBadgesLoading(true));
  firebase
    .firestore()
    .collection("badges")
    .where("userId", "==", userId)
    .onSnapshot(async (data) => {
      let badgesData = [];
      for (let doc of data.docs) {
        badgesData.push({ id: doc.id, ...doc.data() });
      }
      dispatch({ type: "GET_ALL_BADGES", payload: badgesData });
      dispatch(getBadgesLoading(false));
    });
};

export const getSingleBadge = (id) => async (dispatch) => {
  await dispatch(getBadgesLoading(true));
  firebase
    .firestore()
    .collection("badges")
    .doc(id)
    .onSnapshot(async (doc) => {
      let badgeData = {};
      if (doc.data()) {
        badgeData = { id, ...doc.data() };
      }
      dispatch({ type: "GET_SINGLE_BADGE", payload: badgeData });
      dispatch(getBadgesLoading(false));
    });
};

export const addBadge =
  (
    image,
    level,
    userId,
    badgeId,
    onSuccess = () => {},
    setPercentage = () => {}
  ) =>
  async (dispatch) => {
    if (image) {
      setPercentage(50);
      let storageRef = await firebase
        .storage()
        .ref(uuidv4() + ".png")
        .put(image);
      setPercentage(75);

      let imageURL = await storageRef.ref.getDownloadURL();
      setPercentage(85);

      firebase
        .firestore()
        .collection("badges")
        .doc(badgeId)
        .set({
          userId,
          imageURL,
          level: Number(level),
          generatedAt: firebase.firestore.Timestamp.now(),
        })
        .then((res) => {
          onSuccess(imageURL);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setBadgeLoading(false));
          setPercentage(0);
        });
    } else {
      dispatch(setBadgeLoading(false));
      setPercentage(0);
    }
  };
