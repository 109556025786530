import PublicHeader from 'components/Headers/PublicHeader';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Container, Spinner } from 'reactstrap';

import {
	getBadges,
	getSingleBadge,
} from '../store/action/submitQuestionsAction';
import { getAllUsers } from '../store/action/userActions';

import moment from 'moment';

const BadgeVerification = () => {
	const { badges, getBadgesLoading, singleBadge } = useSelector(
		state => state.questions
	);
	const [verificationLoading, setVerificationLoading] = useState(false);
	const { users, usersLoading } = useSelector(state => state.user);
	const { user } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const { id } = useParams();

	const [badge, setBadge] = useState({});

	const getBadge = id => {
		setVerificationLoading(true);
		if (badges?.length > 0) {
			let badge = badges.find(bdge => bdge.id == id);

			if (badge) {
				setVerificationLoading(false);
				return badge;
			} else {
				setVerificationLoading(false);
				return;
			}
		} else {
			setVerificationLoading(false);
			return;
		}
	};

	const getUserData = id => {
		if (users?.length > 0) {
			let obj = users.find(user => user.id == id);
			if (obj) {
				return obj;
			} else {
				return;
			}
		} else {
			return;
		}
	};

	useEffect(() => {
		// if (badges?.length == 0) {
		// 	dispatch(getBadges(user.id));
		// }
		if (users?.length == 0) {
			dispatch(getAllUsers());
		}
	}, []);

	const setBadgeData = id => {
		setVerificationLoading(true);
		if (badges?.length > 0) {
			let badge = badges.find(bdge => bdge.id == id);
			if (badge) {
				setBadge(badge);
				setVerificationLoading(false);
			} else {
				setBadge({});
				setVerificationLoading(false);
			}
		} else {
			setVerificationLoading(false);
			setBadge({});
		}
	};

	const getLimitedEmail = email => {
		if (email) {
			let first2Chars = email.substring(0, 2);
			let last2Chars = email.substring(email.length - 2, email.length);
			console.log(first2Chars);
			return first2Chars + '**************' + last2Chars;
		} else {
			return;
		}
	};

	const getUserGrade = level => {
		switch (Number(level)) {
			case 0:
				return 'A+';
			case 1:
				return 'A';
				break;
			case 2:
				return 'B';
				break;
			case 3:
				return 'C';
			default:
				return;
		}
	};

	useEffect(() => {
		if (id) {
			dispatch(getSingleBadge(id));
		}
	}, [id]);

	console.log('Single badge', singleBadge);

	return (
		<>
			<PublicHeader />
			<Container
				style={{ height: '80vh' }}
				className='d-flex justify-content-center align-items-center px-2'
			>
				{getBadgesLoading || verificationLoading || usersLoading ? (
					<Spinner size='md' />
				) : (
					<>
						{Object.keys(singleBadge).length > 0 ? (
							<Alert color='success'>
								<h1 className='text-center text-white'>
									Verified
								</h1>
								<h2 className='text-center text-white'>
									It is a valid AgileGrade certification,
									grade{' '}
									<strong>
										{getUserGrade(singleBadge.level)}
									</strong>{' '}
									for{' '}
									<strong>
										{
											getUserData(singleBadge.userId)
												?.first_name
										}{' '}
										{
											getUserData(singleBadge.userId)
												?.last_name
										}
									</strong>{' '}
									<strong>
										{getLimitedEmail(
											getUserData(singleBadge.userId)
												?.email
										)}
									</strong>{' '}
									generated at
									<br />
									{singleBadge.generatedAt &&
										moment
											.unix(
												singleBadge.generatedAt.seconds
											)
											.format('DD MMM YYYY HH:mm:ss')}
									.
								</h2>
							</Alert>
						) : (
							<Alert color='danger'>
								<h1 className='text-center text-white'>
									Not Verified
								</h1>
								<h2 className='text-center text-white'>
									It is not a valid AgileGrade certification.
								</h2>
							</Alert>
						)}
					</>
				)}
			</Container>
		</>
	);
};

export default BadgeVerification;
