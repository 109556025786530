import React from 'react';

const CourseCard = ({ name, title, link }) => {
	return (
		<div className='course-card d-flex flex-column h-100'>
			<div className='course-card-icon d-flex align-items-center justify-content-center'>
				<i className='fa fa-book'></i>
			</div>
			<h4>“{title}”</h4>
			<p className='mb-1 mt-auto'>Download for free following this link:</p>
			<div className='d-flex justify-content-end'>
				<a href={link} target='_blank' className='d-flex align-items-center justify-content-center course-card-btn'>
					{name}
				</a>
			</div>
		</div>
	);
};

export default CourseCard;
