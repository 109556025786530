import firebase from "../firebase";
import { toast } from "react-toastify";
export const loginLoading = (val) => async (dispatch) => {
  dispatch({ type: "LOGIN_LOADING", payload: val });
};
export const signUpGoogle = (history) => async (dispatch) => {
  dispatch(loginLoading(true));
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (query) => {
      // var FreshUser = await firebase
      // 	.firestore()
      // 	.collection('users')
      // 	.doc(query.user.uid)
      // 	.get();
      await firebase
        .firestore()
        .collection("users")
        .doc(query.user.uid)
        .onSnapshot(async (userQuery) => {
          let FreshUser = userQuery.data();
          if (!FreshUser) {
            firebase
              .firestore()
              .collection("users")
              .doc(query.user.uid)
              .set({
                email: query.user.email,
                phone: query.user.phone ? query.user.phone : "",
                first_name: query.user.displayName.split(" ")[0],
                last_name: query.user.displayName.split(" ")[1]
                  ? query.user.displayName.slice(
                      query.user.displayName.split(" ")[0].length
                    )
                  : "",
                profile_image: query.user.photoURL ? query.user.photoURL : "",
                type: "google",
              })
              .then(() => {
                dispatch({
                  type: "SIGNUP_SUCCESS",
                  payload: {
                    id: query.user.uid,
                    email: query.user.email,
                    phone: query.user.phone ? query.user.phone : "",
                    first_name: query.user.displayName.split(" ")[0],
                    last_name: query.user.displayName.split(" ")[1]
                      ? query.user.displayName.slice(
                          query.user.displayName.split(" ")[0].length
                        )
                      : "",
                    profile_image: query.user.photoURL
                      ? query.user.photoURL
                      : "",
                    type: "google",
                  },
                });
                dispatch(loginLoading(false));

                history.push("/admin/buy-cert");
              });
          } else {
            dispatch({
              type: "SIGN_IN",
              payload: {
                id: query.user.uid,
                ...FreshUser,
              },
            });
            dispatch(loginLoading(false));

            // history.push('/admin/index');
          }
        });
    })
    .catch((error) => {
      dispatch(loginLoading(false));
    });
};

export const register = (credentials, password, history) => (dispatch) => {
  dispatch(loginLoading(true));

  firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, password)
    .then((userCredentials) => {
      userCredentials.user.sendEmailVerification();
      firebase
        .firestore()
        .collection("users")
        .doc(userCredentials.user.uid)
        .set(credentials)
        .then((res) => {
          dispatch(loginLoading(false));
          toast.success("Verfication email send!");
          history.push("/auth/login");
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(loginLoading(false));
        });
    })
    .catch((err) => {
      toast.error(err.message);
      dispatch(loginLoading(false));
    });
};

export const login = (credentials) => (dispatch) => {
  dispatch(loginLoading(true));

  firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then((data) => {
      if (data.user.emailVerified) {
        firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid)
          .onSnapshot((userQuery) => {
            if (userQuery.data()) {
              dispatch({
                type: "SIGN_IN",
                payload: {
                  id: data.user.uid,
                  ...userQuery.data(),
                },
              });
              dispatch(loginLoading(false));
            } else {
              toast.warning("Invalid user!");
              dispatch(loginLoading(false));
            }
          });
      } else {
        toast.warning("Email not verified!");
        dispatch(loginLoading(false));
      }
    })
    .catch((err) => {
      toast.error(err.message);
      dispatch(loginLoading(false));
    });
};

export const forgotPassword = (email, onComplete) => async (dispatch) => {
  try {
    await firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: "https://agilegrade.com/auth/login",
      });
    toast.success("Password reset email link sent!");
  } catch (err) {
    toast.success(err.message);
  } finally {
    onComplete();
  }
};

export const logout = () => async (dispatch) => {
  setTimeout(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        dispatch({ type: "LOGOUT_SUCCESS" });
      });
  }, 1500);
};
export const changeWelcomeStatus = (user) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .doc(user.id)
    .update({ isWelcomeShown: true })
    .then(() => {
      user.isWelcomeShown = true;
      dispatch({ type: "SIGN_IN", payload: user });
    });
};
