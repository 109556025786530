const initialState = {
  loading: false,
  questions: [],
  submissions: {},
  positions: [],
  leaderBoardLoading: false,
  importQueLoading: false,
  myScore: {},
  badges: [],
  singleBadge: {},
  badgeLoading: false,
  getBadgesLoading: false,
  grade: 4,
  totalQuestions: 10,
};
const submitQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "QUESTIONS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "GET_QUESTIONS":
      return {
        ...state,
        questions: action.payload,
      };
    case "GET_USERS_SUBMISSION":
      return {
        ...state,
        submissions: action.payload,
      };
    case "GET_MY_SCORE":
      console.log({ myScore: action.payload });
      return {
        ...state,
        myScore: action.payload,
      };
    case "GET_LEADER_BOARD":
      return {
        ...state,
        positions: action.payload,
      };
    case "GET_ALL_BADGES":
      return {
        ...state,
        badges: action.payload,
      };
    case "SET_LEADER_BOARD_LOADING":
      return {
        ...state,
        leaderBoardLoading: action.payload,
      };
    case "SET_IMPORT_QUESTIONS_LOADING":
      return {
        ...state,
        importQueLoading: action.payload,
      };
    case "SET_USER_GRADE":
      return {
        ...state,
        grade: action.payload,
      };
    case "SET_BADGE_LOADING":
      return {
        ...state,
        badgeLoading: action.payload,
      };
    case "GET_BADGES_LOADING":
      return {
        ...state,
        getBadgesLoading: action.payload,
      };
    case "GET_SINGLE_BADGE":
      return {
        ...state,
        singleBadge: action.payload,
      };

    default:
      return state;
  }
};
export default submitQuestionsReducer;
