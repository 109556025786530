import {
	Card,
	CardHeader,
	Table,
	Container,
	Row,
	Nav,
	NavItem,
	NavLink,
	Spinner,
	Badge,
} from 'reactstrap';
import OnlyHeader from 'components/Headers/OnlyHeader.js';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
	getLeaderBoard,
	getUsersSubmission,
	getQuestions,
} from '../store/action/submitQuestionsAction';
import { getAllUsers } from 'store/action/userActions';

const LeaderBoard = () => {
	let [activeTab, setActiveTab] = useState('1');
	const dispatch = useDispatch();
	const {
		positions,
		leaderBoardLoading,
		questions,
		questionsLoading,
		submissions,
		totalQuestions,
	} = useSelector(state => state.questions);
	const { users, usersLoading } = useSelector(state => state.user);
	const { user } = useSelector(state => state.auth);

	const getUserData = id => {
		if (users && users.length > 0) {
			let obj = users.find(user => user.id == id);
			if (obj) {
				return obj.first_name + ' ' + obj.last_name;
			} else {
				return 'N/A';
			}
		} else {
			return 'N/A';
		}
	};
	const getMyPosition = id => {
		if (positions && positions.length > 0) {
			let obj = [];
			positions.forEach((pos, idx) => {
				if (pos.id == id) {
					obj.push({ ranking: idx + 1, ...pos });
				}
			});

			return obj;
		} else {
			return [];
		}
	};

	useEffect(() => {
		if (users && users.length == 0) {
			dispatch(getAllUsers());
		}
		if (positions && positions.length == 0) {
			dispatch(getLeaderBoard());
		}

		if (questions && questions.length == 0) {
			dispatch(getQuestions());
		}
		if (Object.keys(submissions).length === 0) {
			dispatch(getUsersSubmission(user.id));
		}
	}, []);

	const getGrade = submission => {
		console.log({ submission });
		if (
			Object.keys(submission).length > 0 &&
			submission.answers_submitted &&
			submission.answers_submitted.length > 0
		) {
			let questionLength =
				submission.answers_submitted.length != 0
					? submission.answers_submitted.length
					: 1;
			let percent = (submission.score * 100) / questionLength;
			switch (true) {
				case percent == 100:
					return 'A+';
				case percent >= 90:
					return 'A';
				case percent >= 80:
					return 'B';
				case percent >= 70:
					return 'C';
				default:
					return '';
			}
		} else {
			return 'D';
		}
	};

	return (
		<>
			<OnlyHeader />
			{/* Page content */}
			<Container className='mt--7' fluid>
				{/* Table */}
				<Row>
					<div className='col'>
						<Card className='shadow mb-4'>
							<CardHeader className='border-0'>
								<h3 className='mb-0'>Leader Board</h3>
							</CardHeader>
							{usersLoading || leaderBoardLoading ? (
								<div className='p-5 d-flex justify-content-center align-items-center'>
									<Spinner size='lg' />
								</div>
							) : (
								<>
									{/* <Nav tabs className='pl-3'>
										<NavItem className='cursor-pointer'>
											<NavLink
												className={classnames({
													active: activeTab === '1',
												})}
												onClick={() => {
													setActiveTab('1');
												}}
											>
												Global Positions
											</NavLink>
										</NavItem>
										<NavItem className='cursor-pointer'>
											<NavLink
												className={classnames({
													active: activeTab === '2',
												})}
												onClick={() => {
													setActiveTab('2');
												}}
											>
												My Position
											</NavLink>
										</NavItem>
									</Nav> */}
									<Table
										className={`${
											activeTab == '1'
												? 'align-items-center table-flush score-table'
												: 'd-none'
										}`}
										responsive
									>
										<thead className='thead-light'>
											<tr className='score-table__rank'>
												<th scope='col' className=''>
													Rank
												</th>
												<th scope='col'>Name</th>
												<th scope='col'>Grade</th>
												{/* <th scope="col" /> */}
											</tr>
										</thead>
										<tbody>
											{positions &&
												positions.length > 0 &&
												positions
													.slice(0)
													.filter(
														pos =>
															pos
																.answers_submitted
																.length >=
															totalQuestions
													)
													.map((pos, idx) => {
														return (
															<tr>
																<td>
																	{idx + 1 <
																	4 ? (
																		<Badge color='success'>
																			<b>
																				{idx +
																					1}
																			</b>
																		</Badge>
																	) : (
																		idx + 1
																	)}
																</td>
																<td className='text-uppercase'>
																	{getUserData(
																		pos.id
																	)}
																</td>
																<td>
																	{getGrade(
																		pos
																	)}
																</td>
																{/* <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td> */}
															</tr>
														);
													})}
										</tbody>
									</Table>

									<Table
										className={`${
											activeTab == '2'
												? 'align-items-center table-flush'
												: 'd-none'
										}`}
										responsive
									>
										<thead className='thead-light'>
											<tr>
												<th scope='col'>Ranking</th>
												<th scope='col'>Name</th>
												<th scope='col'>Score</th>

												{/* <th scope="col" /> */}
											</tr>
										</thead>
										<tbody>
											{positions &&
												positions.length > 0 &&
												getMyPosition(user.id).map(
													pos => {
														return (
															<tr>
																<td>
																	{[
																		1, 2, 3,
																	].includes(
																		Number(
																			pos.ranking
																		)
																	) ? (
																		<Badge color='success'>
																			<b>
																				{
																					pos.ranking
																				}
																			</b>
																		</Badge>
																	) : (
																		pos.ranking
																	)}
																</td>
																<td>
																	{getUserData(
																		user.id
																	)}
																</td>
																<td>
																	{pos.score}
																</td>
																{/* <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
															</tr>
														);
													}
												)}
										</tbody>
									</Table>
								</>
							)}

							{/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default LeaderBoard;
