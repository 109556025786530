import Main from 'components/Main';
import React from 'react';

const Home = () => {
	return (
		<div>
			<Main />
		</div>
	);
};

export default Home;
