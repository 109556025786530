import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { logout } from 'store/action/authActions';

const Logout = () => {
	let dispatch = useDispatch();
	useEffect(() => {
		dispatch(logout());
	}, []);
	return (
		<div className='d-flex align-items-center justify-content-center h-full'>
			<div className='d-flex flex-column align-items-center'>
				<Spinner />
				<span className='d-block mt-2'>Logout</span>
			</div>
		</div>
	);
};

export default Logout;
