import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "store/action/paymentActions";
import { deletePayment } from "store/action/paymentActions";
import { useHistory } from "react-router-dom";
import { price } from "const";

const Paypal = (props) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { userPayment } = useSelector((state) => state.payment);
  const history = useHistory();
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: price,
            },
            reference_id: user?.id,
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id":
            "AVvQmkLuVSMFjouSTMqyckzLNZiUJXEy4hECg_ZoIHIFM9dQ7lqzhhDCE2mvUCvgpn_ouHNIQV79gjQI",
          currency: "USD",
        }}
      >
        <PayPalButtons
          fundingSource={FUNDING.PAYPAL}
          createOrder={createOrder}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              var transaction = details.purchase_units[0].payments.captures[0];

              let obj = {
                payment_id: transaction.id,
                user_id: user?.id,
              };
              if (props.type) {
                dispatch(deletePayment(userPayment.id));
                props.resetData();
                dispatch(
                  createPayment({ ...obj, type: "reset" }, () => {
                    props.paymentToggle();
                    history.push("/admin/start-assessment");
                  })
                );
              } else {
                dispatch(
                  createPayment({ ...obj, type: "initial" }, () => {
                    props.paymentToggle();
                    history.push("/admin/start-assessment");
                  })
                );
              }
            });
          }}
          onCancel={(data, actions) => {}}
          onError={(err) => {
            console.log("err:", err.message);
            toast.error("Payment Failed!");
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default Paypal;
