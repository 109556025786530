import firebase from 'firebase';

export const setUserLoading = val => async dispatch => {
	dispatch({ type: 'SET_USERS_LOADING', payload: val });
};

export const getAllUsers = () => async dispatch => {
	dispatch(setUserLoading(true));

	firebase
		.firestore()
		.collection('users')
		.onSnapshot(async data => {
			let usersData = [];
			for (let doc of data.docs) {
				usersData.push({ id: doc.id, ...doc.data() });
			}
			dispatch({ type: 'GET_ALL_USERS', payload: usersData });
			dispatch(setUserLoading(false));
		});
};
